import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';


type Props = {
    setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
    setLanguage: React.Dispatch<React.SetStateAction<string>>;
    language: string;
};

export const About: React.FunctionComponent<Props> = (props) => {
    const {language, setCurrentPage, setLanguage} = props;

    setCurrentPage("about")

    if (language === "da"){
        return (
            <div className='About' > 
                <h1 style={{paddingTop: 10}}> Om os</h1>
                
                <Box display="flex" justifyContent="center" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
                    <Card sx={{ width: 345, margin: 2}}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image="images/nicklas.jpg"
                                alt="green iguana"
                            />
                            <CardContent sx={{height: 150}}>
                            <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                Nicklas Rokkjær Jensen
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="start">
                                22 år født og opvokset i Breum 7870 Roslev.
                                Jeg er uddannet landmand fra Asmildkloster Landbrugsskole i 2021.
                                Jeg har min daglige gang på Eskjær Hovedgaard i salling hvor jeg er i marken. 
                                A/N Agro driver jeg i min fritid som er blevet meget mere end bare en hobby.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to='tel:+4551145468'>
                                <Button size="small" color="primary">
                                    +45 51 14 54 68
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: 345, margin: 2 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image="images/asbjoern.jpg"
                                alt="green iguana"
                            />
                            <CardContent sx={{height: 150}}>
                            <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                Asbjørn Timm Høgh
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="start">
                                29år født og opvokset i Thise 7870 Roslev. 
                                Jeg er uddannet Jordbrugs maskinfører i beder Århus Fra Jordbrugets uddannelsescenter. 
                                Min dagligdag forgår på DK- Agro lidt udenfor skive hvor jeg er ansat i marken.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to='tel:+4530339560'>
                                <Button size="small" color="primary">
                                    +45 30 33 95 60
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Card sx={{ maxWidth: 725, margin: 2 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image="images/traktor.jpg"
                                alt="green iguana"
                            />
                            <CardContent sx={{height: 150}}>
                            <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                Historien bag A/N Agro 
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="start">
                                A/N Agro blev grundlagt i 2020 hvor vi gik og snakkede om at skulle have en skårlægger til at lave lidt hø til Nicklas’ får,
                                 vi fik købt en skårlægger og fik lavet minirundballe wrap det første år. 
                                Det udviklede sig hurtig til flere og større maskiner samt kundekartoteket voksede og interessen blev stor for wrapproduktion.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to='tel:+4551145468'>
                                <Button size="small" color="primary">
                                    +45 51 14 54 68 (hoved nummer)
                                </Button>
                            </Link>
                            <Link to='tel:+4530339560'>
                                <Button size="small" color="primary">
                                    +45 30 33 95 60 
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Box>
            </div>
        );
    }

    if (language === "en"){
        return (
            <div className='About' > 
                <h1 style={{paddingTop: 10}}> About us</h1>
                <Box display="flex" justifyContent="center" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
                    <Card sx={{ width: 345, margin: 2}}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image="images/nicklas.jpg"
                                alt="green iguana"
                            />
                            <CardContent sx={{height: 150}}>
                            <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                Nicklas Rokkjær Jensen
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="start">
                                22 years old, born and raised in Breum 7870 Roslev.
                                I graduated as a farmer from Asmildkloster Agricultural School in 2021.
                                I spend my daily life at Eskjær Hovedgaard in Salling, where I work in the field.
                                A/N Agro is my side business, which has become much more than just a hobby.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to='tel:+4551145468'>
                                <Button size="small" color="primary">
                                    +45 51 14 54 68
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: 345, margin: 2 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image="images/asbjoern.jpg"
                                alt="green iguana"
                            />
                            <CardContent sx={{height: 150}}>
                            <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                Asbjørn Timm Høgh
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="start">
                                29 years old, born and raised in Thise 7870 Roslev.
                                I am a trained agricultural machine operator from Beder Århus at the Agricultural Education Center.
                                My daily life takes place at DK-Agro just outside Skive, where I am employed in the field.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to='tel:+4530339560'>
                                <Button size="small" color="primary">
                                    +45 30 33 95 60
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Card sx={{ maxWidth: 725, margin: 2 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image="images/traktor.jpg"
                                alt="green iguana"
                            />
                            <CardContent sx={{height: 150}}>
                            <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                The story behind A/N Agro 
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="start">
                            A/N Agro was founded in 2020 when we were talking about getting a mower to make some hay for Nicklas' sheep. We bought a mower and made mini-round bale wraps the first year. It quickly developed into more and larger machinery, and the customer base grew. There was a significant interest in wrap production.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to='tel:+4551145468'>
                                <Button size="small" color="primary">
                                    +45 51 14 54 68 (hoved nummer)
                                </Button>
                            </Link>
                            <Link to='tel:+4530339560'>
                                <Button size="small" color="primary">
                                    +45 30 33 95 60 
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Box>
            </div>
        );
    }
};