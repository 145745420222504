import { Email, Facebook, Instagram, Phone, Place } from '@mui/icons-material';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
    setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
    language: string;
};

export const Contact: React.FunctionComponent<Props> = (props) => {
    const {language, setCurrentPage} = props;

    setCurrentPage("contact");
    
    if (language === "da"){
        return (
            <div className='Contact' > 
                <h1 style={{paddingBottom: 50, paddingTop: 20}}> Kontakt os</h1>
                
                <Stack spacing={5} textAlign="start">
                    <Box display="flex" justifyContent="space-evenly" alignItems="center" flexDirection={{ xs: 'column-reverse', md: 'row' }}>
                        <Stack spacing={8} padding={{ xs: '0', md: '0 100pt' }}>
                            <Stack direction="row" spacing={1}>
                                <Phone/>
                                <a href='tel:+4551145468' style={{textDecoration: "none", color: "black"}}> <Typography fontFamily="inherit"> <b>+45 51 14 54 68</b></Typography> </a>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Phone/>
                                <a href='tel:+4530339560' style={{textDecoration: "none", color: "black"}}> <Typography fontFamily="inherit"><b> +45 30 33 95 60</b></Typography> </a>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Email/>
                                <a href='mailto:anagro7870@gmail.com' style={{textDecoration: "none", color: "black"}}> <Typography fontFamily="inherit"><b>anagro7870@gmail.com</b></Typography></a>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Facebook/>
                                <a href='https://www.facebook.com/profile.php?id=100057154941790' style={{textDecoration: "none", color: "black"}}> <Typography fontFamily="inherit"><b>A/N Agro</b></Typography></a>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Place/>
                                <a target="_blank" href='https://www.google.com/maps/place/Fogedvej+2,+7870+Roslev/@56.6862054,9.0764187,17z/data=!3m1!4b1!4m6!3m5!1s0x46496498b37b54e3:0x4cb8b39bb9e46134!8m2!3d56.6862054!4d9.0789936!16s%2Fg%2F11c5glbs4q?entry=ttu' style={{textDecoration: "none", color: "black"}}>
                                <Typography fontFamily="inherit"><b>Fogedvej 2, 7870 Roslev</b></Typography>    
                                </a>
                            </Stack>
                        </Stack>
                        <Box display={{ xs: 'none', md: 'block' }}>
                            <img src='images/anagrohome.jpg' height="500px"/>
                        </Box>
                    </Box>

                    <Stack>
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2191.3039351782827!2d9.076413313278602!3d56.686208319602166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46496498b37b54e3%3A0x4cb8b39bb9e46134!2sFogedvej%202%2C%207870%20Roslev!5e0!3m2!1sen!2sdk!4v1708789256248!5m2!1sen!2sdk" 
                            width="auto" 
                            height="400" 
                        />
                    </Stack>
                </Stack>
            </div>
        );
    }
    
    if (language === "en") {
        return (
            <div className='Contact' > 
                <h1 style={{paddingBottom: 50, paddingTop: 20}}> Contact</h1>
                <Stack spacing={5} textAlign="start">
                    <Box display="flex" justifyContent="space-evenly" alignItems="center" flexDirection={{ xs: 'column-reverse', md: 'row' }}>
                        <Stack spacing={8} padding={{ xs: '0', md: '0 100pt' }}>
                            <Stack direction="row" spacing={1}>
                                <Phone/>
                                <a href='tel:+4551145468' style={{textDecoration: "none", color: "black"}}> <Typography fontFamily="inherit"> <b>+45 51 14 54 68</b></Typography> </a>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Phone/>
                                <a href='tel:+4530339560' style={{textDecoration: "none", color: "black"}}> <Typography fontFamily="inherit"><b> +45 30 33 95 60</b></Typography> </a>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Email/>
                                <a href='mailto:anagro7870@gmail.com' style={{textDecoration: "none", color: "black"}}> <Typography fontFamily="inherit"><b>anagro7870@gmail.com</b></Typography></a>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Facebook/>
                                <a href='https://www.facebook.com/profile.php?id=100057154941790' style={{textDecoration: "none", color: "black"}}> <Typography fontFamily="inherit"><b>A/N Agro</b></Typography></a>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Place/>
                                <a target="_blank" href='https://www.google.com/maps/place/Fogedvej+2,+7870+Roslev/@56.6862054,9.0764187,17z/data=!3m1!4b1!4m6!3m5!1s0x46496498b37b54e3:0x4cb8b39bb9e46134!8m2!3d56.6862054!4d9.0789936!16s%2Fg%2F11c5glbs4q?entry=ttu' style={{textDecoration: "none", color: "black"}}>
                                <Typography fontFamily="inherit"><b>Fogedvej 2, 7870 Roslev</b></Typography>    
                                </a>
                            </Stack>
                        </Stack>
                        <Box display={{ xs: 'none', md: 'block' }}>
                            <img src='images/anagrohome.jpg' height="500px"/>
                        </Box>
                    </Box>

                    <Stack>
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2191.3039351782827!2d9.076413313278602!3d56.686208319602166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46496498b37b54e3%3A0x4cb8b39bb9e46134!2sFogedvej%202%2C%207870%20Roslev!5e0!3m2!1sen!2sdk!4v1708789256248!5m2!1sen!2sdk" 
                            width="auto" 
                            height="400" 
                        />
                    </Stack>
                </Stack>
            </div>
        );
    }
    
};