import { Box, Stack, Typography } from "@mui/material";
import React from "react";


export const Footer: React.FunctionComponent = () => {


    return(
        <footer style={{position: "sticky", bottom: 0, width: "100%"}}>
            
            <Box style={{display: 'flex', flexFlow: "wrap", justifyContent: 'space-evenly', padding: 10, backgroundColor: "lightgrey", alignItems: "center"}}>
                <Box>
                    <Typography> A/N Agro </Typography>
                    <Typography>CVR: 41428791 </Typography>

                </Box>
                <span>
                <a target="_blank" href='https://www.google.com/maps/place/Fogedvej+2,+7870+Roslev/@56.6862054,9.0764187,17z/data=!3m1!4b1!4m6!3m5!1s0x46496498b37b54e3:0x4cb8b39bb9e46134!8m2!3d56.6862054!4d9.0789936!16s%2Fg%2F11c5glbs4q?entry=ttu' style={{textDecoration: "none", color: "black"}}>
                    Fogedvej 2, 7870 Roslev
                </a>
                </span>
                <span><a href="mailto:anagro7870@gmail.com" style={{textDecoration: "none", color: "black"}}> anagro7870@gmail.com</a></span>
                <Stack>
                    <span><a href="tel:+4551145468" style={{textDecoration: "none", color: "black"}}>+45 51 14 54 68</a></span>
                    <span><a href="tel:+4530339560" style={{textDecoration: "none", color: "black"}}>+45 30 33 95 60</a></span>
                </Stack>
            </Box>
            {/* <Box sx={{height: 4, backgroundColor: "yellow"}}/>              */}
        </footer>
    );
}