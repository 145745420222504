import { AppBar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import LanguageIcon from '@mui/icons-material/Language';
import { Logout, PersonAdd, Settings } from '@mui/icons-material';

type Props = {
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  language: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
  currentPage: string
};

export const Header: React.FunctionComponent<Props> = (props) => {
  const {setLanguage, language, currentPage, setCurrentPage} = props;

  

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <header>
      <AppBar position='static' sx={{backgroundColor: "green", height: "60px"}}>
        <Box sx={{display: 'flex',
          justifyContent: 'space-evenly',
          alignContent: 'center', alignItems: "center", height: "60px"}}>
        <Box>
        <Link to="/" onClick={() => setCurrentPage("home")} style={{textDecoration: 'none'}}>
            <Typography color={currentPage === "home" ? "red" : "white"}>
                <b>A/N Agro</b>
            </Typography>
        </Link>
        </Box>
        <Link to="/products" onClick={() => setCurrentPage("products")} style={{textDecoration: 'none'}}>
          {language === "en" && <Typography color={currentPage === "products" ? "red" : "white"}>Products</Typography>}
          {language === "da" && <Typography color={currentPage === "products" ? "red" : "white"}>Produkter</Typography>}
        </Link>
        <Link to="/about" onClick={() => setCurrentPage("about")} style={{textDecoration: 'none'}}>
          {language === "en" && <Typography color={currentPage === "about" ? "red" : "white"}>About</Typography>}
          {language === "da" && <Typography color={currentPage === "about" ? "red" : "white"}>Om os</Typography>}
        </Link>
        <Link to="/contact" onClick={() => setCurrentPage("contact")} style={{textDecoration: 'none'}}>
          {language === "en" && <Typography color={currentPage === "contact" ? "red" : "white"}>Contact</Typography>}
          {language === "da" && <Typography color={currentPage === "contact" ? "red" : "white"}>Kontakt</Typography>}
        </Link>

        <IconButton color='inherit' onClick={handleClick}>
            <LanguageIcon />
        </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => {
            handleClose();
            setLanguage("da");
            }}>
            <Typography color={language === "da" ? "green" : "black"}>Dansk</Typography>
          </MenuItem>
          <MenuItem onClick={() => {
            handleClose();
            setLanguage("en");
            }}>
            <Typography color={language === "en" ? "green" : "black"}>English</Typography>
          </MenuItem>
          {/* <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem> */}
        </Menu>
      </AppBar>
    </header>
  );
};