import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

type Props = {
    language: string;
    setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
};

export const Products: React.FunctionComponent<Props> = (props) => {
    const {language, setCurrentPage} = props;

    setCurrentPage("products");

    if(language === "da"){
        return (
            <div className='products' style={{margin: "0 10%"}}> 
                <h1 style={{paddingTop: 20, marginBottom: 50}}> Produkter & Services</h1>
                <Stack spacing={2} paddingBottom={10}>
                <Box textAlign="start" >
                    <Typography variant='h3' component="h2" gutterBottom>Hvad tilbyder vi?</Typography>
                    <Typography gutterBottom>
                        Vi hos A/N Agro tilbyder en bred vifte af services, 
                         hvor vi varetager alt indenfor produktion af wrap. 
                        Vi kommer gerne og giver et tilbud hos dig om hvordan vi skal producere dit næste slet.
                    </Typography>
                    <Typography>
                        <b>Gødskning</b> med Bøgballe 12 til 24 meter
                    </Typography>
                    <Typography>
                        <b>Slåning</b> med Claas Disco 3600 contur 3,6 meter
                    </Typography>
                    <Typography>
                        <b>Vending</b> claas volto 7m 6 roters
                    </Typography>
                    <Typography>
                        <b>Sam rivning</b> med Claas liner 2900 Trend 7-9m
                    </Typography>
                    <Typography>
                        <b>Skårluftning</b> med fransgaard 250
                    </Typography>
                    <Typography>
                        <b>Presning i minibig</b> med New holland BB940 cropcutter med samlevogn til 5 baller
                    </Typography>
                    <Typography>
                        <b>Indpakning</b> med Göweil 3010
                    </Typography>
                    <Typography gutterBottom>
                        <b>Hjemkørsel samt stakning</b>
                    </Typography>
                </Box>
                <Box textAlign="start">
                    <Typography gutterBottom>
                    Vi producerer selv 700-1000 wrapballer til salg (stigende hvert år) på vores udlagte Horsemax blandinger med fokus på heste. VI laver wrap aftaler til store samt små kunder. Og vi leverer med Lastbil i hele Danmark samt med traktor og vogn i nærområdet, vi har vores fokus på kunder hvor vi stræber efter den bedste oplevelse og løsning til dig og din hest. Kunden er altid i højsædet og vi er mere end glade for et godt samarbejde vi hjælper gerne med et samlet bud på et års forbrug af wrap, hø samt halm.
                    </Typography>
                    
                </Box>
                <Box textAlign="start">
                    <Typography gutterBottom>
                    Halm salg og formidling er en stor del af vores forretning vi opkøber halm på skår og halm fra hus til videresalg til hesteverdenen og landbrug. Vi presser selv vårbyg halm i mini til vores fastekunder ca 2000stk (varier hvert år)
                    Vi hjælper gerne dig med at komme af med halmen uanset størrelse og type.
                    </Typography>
                </Box>
                <Box textAlign="start" >
                    <Typography gutterBottom>
                    Som noget nyt i virksomheden er vi blevet forhandler og importør af produkter fra den Tyske kvalitets virksomhed Folinet, vi sælger mange varianter af WrapFolie i alle afskygninger og bindegarn i forskellige typer og rundballe net i div størrelser.
                    Tyske Folinet er en familie ejet virksomhed som har kvaliteten i højsædet de er meget store på markedet i Tyskland samt polen. Vi vil prøve at levere samme tryghed og service til det danske marked.
                    </Typography>
                </Box>
    
                </Stack>
            </div>
        );
    }

        if (language === "en") {
            return (
                <div className='Hjem' style={{margin: "0 10%"}}> 
                    <h1 style={{paddingTop: 20, marginBottom: 50}}> Products & Services</h1>
                    <Stack spacing={2} paddingBottom={10}>
                    <Box textAlign="start" >
                        <Typography variant='h3' component="h2" gutterBottom>What do we offer?</Typography>
                        <Typography gutterBottom>
                        At A/N Agro, we offer a wide range of services, taking care of everything related to wrap production. We are available to provide a quote for how we can produce your next field. Our services include:
                        </Typography>
                        <Typography>
                            <b>Fertilization</b> with Bøgballe 12 to 24 meters
                        </Typography>
                        <Typography>
                            <b>Mowing</b> with Claas Disco 3600 contur 3.6 meters
                        </Typography>
                        <Typography>
                            <b>Tedding</b> with Claas Volto 7m 6 roters
                        </Typography>
                        <Typography>
                            <b>Raking</b> with Claas Liner 2900 Trend 7-9m
                        </Typography>
                        <Typography>
                            <b>Swathing</b> with Fransgaard 250
                        </Typography>
                        <Typography>
                            <b>Baling in minibig</b> with New Holland BB940 CropCutter with a collector wagon for 5 bales
                        </Typography>
                        <Typography>
                            <b>Wrapping</b> with Göweil 3010
                        </Typography>
                        <Typography gutterBottom>
                            <b>Transportation and stacking</b>
                        </Typography>
                    </Box>
                    <Box textAlign="start">
                        <Typography gutterBottom>
                        We produce 700-1000 wrapped bales for sale (increasing each year) with a focus on Horsemax mixtures for horses. We offer wrap agreements to both large and small customers, delivering with trucks throughout Denmark and with tractors and wagons in the local area. Our focus is on customers, aiming for the best experience and solution for you and your horse. Customer satisfaction is our priority, and we are delighted to foster good collaborations.
                        </Typography>
                        
                    </Box>
                    <Box textAlign="start">
                        <Typography gutterBottom>
                        Halm (straw) sales and brokerage are significant parts of our business. We purchase straw in the field and from buildings for resale to the equestrian and agricultural sectors. We press our own spring barley straw into mini-bales for our regular customers (approximately 2000 each year, varying). We are ready to assist you in disposing of straw, regardless of quantity or type.
                        </Typography>
                    </Box>
                    <Box textAlign="start" >
                        <Typography gutterBottom>
                        As a new addition to our business, we have become a dealer and importer of products from the German quality company Folinet. We sell various types of wrap film, binding twine in different varieties, and round bale nets in various sizes. Folinet, a family-owned German company, prioritizes quality and holds a significant market presence in Germany and Poland. We aim to provide the same security and service to the Danish market."
                        </Typography>
                    </Box>
        
                    </Stack>
                </div>
            );
        }

        return(
            <>
                Language not supported
            </>
        );
    
};