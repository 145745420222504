import logo from './logo.svg';
import './App.css';

import { Header } from './Components/Header.tsx';
import { Home } from './Pages/Home.tsx';
import { Products } from './Pages/Products.tsx';
import { About } from './Pages/About.tsx';
import { Contact } from './Pages/Contact.tsx';

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, HashRouter } from 'react-router-dom';
import { Footer } from './Components/Footer.tsx';

function App() {

  const [language, setLanguage] = React.useState("da");
  const [currentPage, setCurrentPage] = React.useState("home");
  return (
    <HashRouter basename="/">
      <div className='App'>
        <Header setLanguage={setLanguage} language={language} setCurrentPage={setCurrentPage} currentPage={currentPage}/>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Home language={language}  setCurrentPage={setCurrentPage}/>} />
          <Route path="/products" element={<Products language={language} setCurrentPage={setCurrentPage}/>} />
          <Route path="/about" element={<About language={language} setCurrentPage={setCurrentPage}/>} />
          <Route path="/contact" element={<Contact language={language} setCurrentPage={setCurrentPage}/>} />        
        </Routes>
        <Footer/>
        <div id='credits' style={{fontSize: '8pt', backgroundColor: 'white', textAlign: 'center'}}>
          <p style={{margin: "2px"}}>Made by Marcus Kofoed Kirkegaard |  Copyright © 2023-2024</p>
        </div>
      </div>
    </HashRouter>


    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
