import React from 'react';
import { Carousel } from '../Components/Carousel.tsx';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

type Props = {
    language: string;
    setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
};

export const Home: React.FunctionComponent<Props> = (props) => {
    const {language, setCurrentPage} = props;

    setCurrentPage("home");

    if (language === "en"){ 
        return (
            <div className='Hjem' > 
                <h1 style={{padding: 10}}> Welcome to A/N AGRO I/S</h1>
                
                <Box sx={{justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                    <Carousel/>
                </Box>
                <Box id="Hvad" textAlign="start" sx={{padding: 10}}>
                    <Typography variant="h2" component="h2">What do we offer?</Typography>

                    <Typography gutterBottom>
                        At A/N Agro, we offer a wide range of services, taking care of everything related to wrap production. We are available to provide a quote for how we can produce your next field. Our services include:
                    </Typography>
                    <Typography>
                            <b>Fertilization</b> with Bøgballe 12 to 24 meters
                        </Typography>
                        <Typography>
                            <b>Mowing</b> with Claas Disco 3600 contur 3.6 meters
                        </Typography>
                        <Typography>
                            <b>Tedding</b> with Claas Volto 7m 6 roters
                        </Typography>
                        <Typography>
                            <b>Raking</b> with Claas Liner 2900 Trend 7-9m
                        </Typography>
                        <Typography>
                            <b>Swathing</b> with Fransgaard 250
                        </Typography>
                        <Typography>
                            <b>Baling in minibig</b> with New Holland BB940 CropCutter with a collector wagon for 5 bales
                        </Typography>
                        <Typography>
                            <b>Wrapping</b> with Göweil 3010
                        </Typography>
                        <Typography gutterBottom>
                            <b>Transportation and stacking</b>
                        </Typography>
                    <Link to="/products">
                        <Button>
                            Read more about our products and services here!
                        </Button>
                    </Link>
                </Box>
                <Box id="Hvem" textAlign="start" sx={{paddingLeft: 10, paddingRight: 10}}>
                    <Typography variant="h2" component="h2" sx={{paddingBottom: 10}}>
                        Who are we?
                    </Typography>
                    <Box display="flex" justifyContent="center" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
                    <Card sx={{ width: 345, margin: 2}}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image="images/nicklas.jpg"
                                alt="green iguana"
                            />
                            <CardContent sx={{height: 150}}>
                            <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                Nicklas Rokkjær Jensen
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="start">
                                22 years old, born and raised in Breum 7870 Roslev.
                                I graduated as a farmer from Asmildkloster Agricultural School in 2021.
                                I spend my daily life at Eskjær Hovedgaard in Salling, where I work in the field.
                                A/N Agro is my side business, which has become much more than just a hobby.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to='tel:+4551145468'>
                                <Button size="small" color="primary">
                                    +45 51 14 54 68
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                    <Card sx={{ width: 345, margin: 2 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image="images/asbjoern.jpg"
                                alt="green iguana"
                            />
                            <CardContent sx={{height: 150}}>
                            <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                Asbjørn Timm Høgh
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="start">
                                29 years old, born and raised in Thise 7870 Roslev.
                                I am a trained agricultural machine operator from Beder Århus at the Agricultural Education Center.
                                My daily life takes place at DK-Agro just outside Skive, where I am employed in the field.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to='tel:+4530339560'>
                                <Button size="small" color="primary">
                                    +45 30 33 95 60
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Card sx={{ maxWidth: 725, margin: 2 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image="images/traktor.jpg"
                                alt="green iguana"
                            />
                            <CardContent sx={{height: 150}}>
                            <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                The story behind A/N Agro 
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="start">
                            A/N Agro was founded in 2020 when we were talking about getting a mower to make some hay for Nicklas' sheep. We bought a mower and made mini-round bale wraps the first year. It quickly developed into more and larger machinery, and the customer base grew. There was a significant interest in wrap production.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to='tel:+4551145468'>
                                <Button size="small" color="primary">
                                    +45 51 14 54 68 (hoved nummer)
                                </Button>
                            </Link>
                            <Link to='tel:+4530339560'>
                                <Button size="small" color="primary">
                                    +45 30 33 95 60 
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Box>
                </Box>

                
                
                
                
            </div>
        );
    } 

    if (language === "da"){
        return (
            <div className='Hjem' > 
                <h1 style={{padding: 10}}> Velkommen til A/N AGRO I/S</h1>
                
                <Box sx={{justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                    <Carousel/>
                </Box>
                <Box id="Hvad" textAlign="start" sx={{padding: 10}}>
                    <Typography variant="h2" component="h2">Hvad tilbyder vi?</Typography>

                    <Typography gutterBottom>
                        Vi hos A/N Agro tilbyder en bred vifte af services, 
                        hvor vi varetager alt indenfor produktion af wrap. 
                        Vi kommer gerne og giver et tilbud hos dig om hvordan vi skal producere dit næste slet.
                    </Typography>
                    <Typography>
                        <b>Gødskning</b> med Bøgballe 12 til 24 meter
                    </Typography>
                    <Typography>
                        <b>Slåning</b> med Claas Disco 3600 contur 3,6 meter
                    </Typography>
                    <Typography>
                        <b>Vending</b> claas volto 7m 6 roters
                    </Typography>
                    <Typography>
                        <b>Sam rivning</b> med Claas liner 2900 Trend 7-9m
                    </Typography>
                    <Typography>
                        <b>Skårluftning</b> med fransgaard 250
                    </Typography>
                    <Typography>
                        <b>Presning i minibig</b> med New holland BB940 cropcutter med samlevogn til 5 baller
                    </Typography>
                    <Typography>
                        <b>Indpakning</b> med Göweil 3010
                    </Typography>
                    <Typography gutterBottom sx={{paddingBottom: 5}}>
                        <b>Hjemkørsel samt stakning</b>
                    </Typography>
                    <Link to="/products">
                        <Button>
                            Læs mere om vores produkter og services her!
                        </Button>
                    </Link>
                </Box>
                <Box id="Hvem" textAlign="start" sx={{paddingLeft: 10, paddingRight: 10}}>
                    <Typography variant="h2" component="h2" sx={{paddingBottom: 10}}>
                        Hvem er vi?
                    </Typography>
                    <Box display="flex" justifyContent="center" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
                        <Card sx={{ maxWidth: 345, margin: 2}}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image="images/nicklas.jpg"
                                    alt="green iguana"
                                />
                                <CardContent sx={{height: 150}}>
                                <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                    Nicklas Rokkjær Jensen
                                </Typography>
                                <Typography variant="body2" color="text.secondary" textAlign="start">
                                    22 år født og opvokset i Breum 7870 Roslev.
                                    Jeg er uddannet landmand fra Asmildkloster Landbrugsskole i 2021.
                                    Jeg har min daglige gang på Eskjær Hovedgaard i salling hvor jeg er i marken. 
                                    A/N Agro driver jeg i min fritid som er blevet meget mere end bare en hobby.
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Link to='tel:+4551145468'>
                                    <Button size="small" color="primary">
                                        +45 51 14 54 68
                                    </Button>
                                </Link>
                            </CardActions>
                        </Card>
                        <Card sx={{ maxWidth: 345, margin: 2 }}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image="images/asbjoern.jpg"
                                    alt="green iguana"
                                />
                                <CardContent sx={{height: 150}}>
                                <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                    Asbjørn Timm Høgh
                                </Typography>
                                <Typography variant="body2" color="text.secondary" textAlign="start">
                                    29år født og opvokset i Thise 7870 Roslev. 
                                    Jeg er uddannet Jordbrugs maskinfører i beder Århus Fra Jordbrugets uddannelsescenter. 
                                    Min dagligdag forgår på DK- Agro lidt udenfor skive hvor jeg er ansat i marken.
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Link to='tel:+4530339560'>
                                    <Button size="small" color="primary">
                                        +45 30 33 95 60
                                    </Button>
                                </Link>
                            </CardActions>
                        </Card>
                    </Box>
                    <Box display="flex" justifyContent="center">
                    <Card sx={{ maxWidth: 725, margin: 2 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image="images/traktor.jpg"
                                alt="green iguana"
                            />
                            <CardContent sx={{height: 150}}>
                            <Typography gutterBottom variant="h5" component="h3" textAlign="start">
                                Historien bag A/N Agro 
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="start">
                                A/N Agro blev grundlagt i 2020 hvor vi gik og snakkede om at skulle have en skårlægger til at lave lidt hø til Nicklas’ får,
                                 vi fik købt en skårlægger og fik lavet minirundballe wrap det første år. 
                                Det udviklede sig hurtig til flere og større maskiner samt kundekartoteket voksede og interessen blev stor for wrapproduktion.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Link to='tel:+4551145468'>
                                <Button size="small" color="primary">
                                    +45 51 14 54 68 (hoved nummer)
                                </Button>
                            </Link>
                            <Link to='tel:+4530339560'>
                                <Button size="small" color="primary">
                                    +45 30 33 95 60 
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Box>
                </Box>

                
                
                
                
            </div>
        );
    }

};